
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Work Sans", sans-serif;
  scroll-behavior: smooth;
}

html {
  overflow: hidden;
  height: 100%;
  font-size: 18px;
  color: rgb(90, 90, 90);
  scroll-behavior: smooth;
}

body {
  overflow: auto;
  height: 100%;
}
